module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ismaya TV","short_name":"Ismaya TV","start_url":"/","background_color":"#78c5bf","theme_color":"#78c5bf","display":"standalone","icon":"src/assets/ico/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"2180289fc30a01f5a8ab5dbf2a7e0ea2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TSHNMHS"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
